$jabekon-primary: #004D40;
$jabekon-secondary: #00695C;
$jabekon-sub: #E0F2F1;

$dark: #212121;

$icon-font-path: 'fonts/';

@import "app/libs/bootstrap-sass/assets/stylesheets/_bootstrap";
@import "app/styles/imports/angular-xeditable";
@import "app/styles/imports/daterangepicker";

$base-margin: 6px;
$base-border-radius: 6px;

body {
  padding-top: 70px;
  padding-bottom: 20px;
}

#mainNav {
    &.navbar {
        background: $jabekon-primary;
        border-color: $jabekon-secondary;
        a {
            color: $jabekon-sub;
        }
    }
}

#home {
    a.employee {
        display: block;
        height: 200px;
        line-height: 200px;
        text-align: center;
        background: $dark;
        margin: $base-margin*2;
        border-radius: $base-border-radius;
        color: $jabekon-sub;
        font-size: 2em;
        &:hover {
            color: #999;
            text-decoration: none;
        }
    }

}
#time-register {
    .label {
        color: black;
    }
}


#employee, #order, #admin {
    .editable-wrap {
        width: 100%;
        input {
            width: 100%;
        }
    }
    table {
        background: white;
    }
}

#admin {
    .sidebar {
        position: fixed;
        top: 51px;
        bottom: 0;
        left: 0;
        z-index: 1000;
        display: block;
        padding: 20px;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: #f5f5f5;
        border-right: 1px solid #eee;
    }

}

#footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    p {
        padding: 18px 24px 12px;
    }
}
